@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.scoreScaleWrapper {
  position: fixed;
  z-index: 102;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    background-color: #00000066;
    pointer-events: unset;
    visibility: initial;

    .scoreScale {
      opacity: 1;
    }
  }

  .scoreScale {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 44px);
    max-width: 400px;
    max-height: 400px;
    box-sizing: content-box;
    padding: 24px;
    margin: 0;
    background: color(background);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: padding 0.3s, opacity 0.3s;

    @media #{$xsMedia} {
      padding: 16px;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    &::before,
    &::after {
      display: none;
    }

    button {
      align-self: flex-end;
      color: map-get($primary-colors, primary);

      svg {
        fill: map-get($primary-colors, primary);
      }
    }

    h3 {
      margin: 0;
      font-size: 21px;
      font-weight: 700;
      line-height: 120%;
    }

    p {
      margin-bottom: 16px;
    }

    .scaleTable {
      max-width: 250px;
      border: 1px solid color(archived-dark);
      border-bottom: 0;
      border-radius: 4px;
      border-spacing: 0;
      text-align: left;

      thead {
        background: map-get($legacy-color-names, tooltip);
        color: white;
      }

      th,
      td {
        height: 24px;
        padding: 0 8px;
        border-bottom: 1px solid color(archived-dark);
      }
    }
  }
}

.outerWizardWarningModal {
  position: fixed;
  z-index: 102;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }
}

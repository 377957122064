@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.hubSupport {
  .hubSupportIndex {
    width: 100%;
    padding: 4.6rem 1.5rem 0;

    &:first-of-type {
      padding-top: 2rem;
    }

    &:last-of-type {
      padding-bottom: 2rem;
    }

    .hubSupportTitle {
      padding: 0 0 0.5rem 1rem;
      font-size: 1.3rem;
      font-weight: bold;
      text-align: left;
    }

    .hubSupportTitleLinkBorder {
      width: 100%;
      height: 1px;
      background-color: $color-light-grey;
    }

    .hubSupportArticles {
      .hubSupportLinkBorder {
        width: calc(100% - 1rem);
        height: 1px;
        margin: 0 0 0 1rem;
        background-color: $color-light-grey;
      }

      li:last-child .hubSupportLinkBorder {
        display: none;
      }

      .hubSupportArticleLink {
        display: flex;
        width: 100%;
        min-height: 3.5rem;
        align-items: center;
        padding: 0 1rem;
        color: map-get($secondary-colors, hub_support_link);
        font-weight: 400;
        text-align: unset;

        &:hover {
          background-color: map-get($secondary-colors, hub_light_grey);
        }

        &:focus {
          @include standart-focus-button();

          border-radius: 3px;
        }
      }
    }
  }
}

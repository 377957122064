@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.RvsHomeTopRow {
  font-family: $font-family-text-font;

  .heroContainer {
    position: relative;
    display: flex;
    height: 30rem;
    max-height: 40rem;
    align-items: center;
    justify-content: space-around;
    background-color: map-get($background-and-border-colors, background-dark-blue);

    h1 {
      margin: 1rem 0 0 0;
      color: map-get($rvsColors, rvs_summer_yellow);
      font-family: "DM Serif Display", serif;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.3;
      text-align: left;
    }

    h2 {
      margin: 0 0 1rem 0;
      color: white;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.5;
      text-align: left;
    }

    .heroImage {
      display: none;
      width: 45%;
      margin-left: calc(10px + 1%);
    }

    @media #{$smMedia} {
      height: 18rem;
      padding-right: 24px;
      padding-left: 24px;

      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 14px;
      }

      .heroImage {
        display: block;
        min-width: 20rem;
      }
    }

    @media #{$mdMedia} {
      height: 20rem;

      .heroImage {
        min-width: 22rem;
      }

      h1 {
        font-size: 42px;
      }

      h2 {
        font-size: 18px;
      }
    }

    @media #{$lgMedia} {
      height: 25rem;

      .heroImage {
        min-width: 33rem;
        max-width: 40rem;
      }

      h1 {
        font-size: 54px;
      }

      h2 {
        font-size: 21px;
      }
    }

    @media #{$xsMedia} {
      height: 12rem;
      padding: 0 16px;

      h1 {
        margin-top: 0;
        font-size: 1.5rem;
      }

      h2 {
        font-size: 0.75rem;
      }

      .heroImage {
        display: block;
        width: 40%;
      }
    }

    @media #{$phoneMedia} {
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      padding: 0 16px;

      h1 {
        font-size: 1.5rem;
      }

      h2 {
        margin: 0;
        font-size: 0.75rem;
      }

      .heroImage {
        display: none;
      }
    }
  }
}

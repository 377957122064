@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";
@import "../../../../styles/mixins/rvs/rvs-mixings";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.RvsHomeLowerRow {
  position: relative;
  margin: 32px 24px 16px 24px;
  font-family: $font-family-text-font;

  @media #{$xsMedia} {
    margin: 24px 16px 16px 16px;
  }

  .RvsHomeLowerRowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .InformationBox {
      width: 90%;
      margin-bottom: 40px;
    }

    .TermsContainer {
      display: table;
      width: 90%;
      max-width: 577px;
      margin: 40px 0 80px 0;
      text-align: center;

      @media #{$xsMedia} {
        margin-bottom: 60px;
      }

      p {
        display: table-cell;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.017em;
        line-height: 150%;
        vertical-align: middle;
      }
    }

    .ManualInputContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        margin-top: 40px;
        font-size: 21px;
        font-weight: 700;
      }

      button {
        margin-top: 16px;
      }

      a {
        margin-top: 16px;
      }

      @media #{$xsMedia} {
        p {
          margin-top: 24px;
        }
      }
    }

    .Recaptcha {
      color: map-get($hubColors, dark-gray);
      font-size: 11px;
      font-weight: 400;
      letter-spacing: -0.017em;
      line-height: 150%;

      a {
        @include inline-link();

        color: map-get($hubColors, dark-gray);
      }
    }

    .MaintenanceMessageContainer {
      display: flex;
      max-width: 577px;
      flex-direction: column;
      align-items: center;
      padding: 7px 30px;
      background: #4770a733;
      border-radius: 4px;
      letter-spacing: -0.02em;
      margin-bottom: 20px;

      a {
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}

.CertificateUploadContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.DropZoneContainer {
  display: flex;
  width: 90%;
  max-width: 578px;
  flex-direction: column;
}

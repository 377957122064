@import "../../styles/constants/colors";
@import '../../styles/constants/variables';

.container {
  width: 100%;
  height: 100%;
  background: color(selected);
}

.form {
  margin-top: 88px;
}

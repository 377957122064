@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";
@import "../../styles/mixins/hub/hub-button-focus";

.hubSupportIndexItem {
  .thumbnailContainer {
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      box-sizing: content-box;
      fill: map-get($hubColors, blue-color-primary);
      transition: 0.1s ease-out transform;
    }
  }

  .thumbnailActive svg {
    transform: rotate(-90deg);
    transition-duration: 0.3s;
  }

  .dropdownButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      background-color: map-get($secondary-colors, hub_light_grey);

      svg {
        fill: black;
      }
    }
  }

  .buttonName {
    position: relative;
    width: 100%;
    padding: 0.8125rem 1rem 0.8125rem 0.6875rem;
    cursor: pointer;
    text-transform: capitalize;
  }

  .listArticlesClosed {
    overflow: hidden;
    height: 0;
    transition-duration: 0.3s;
  }

  .listArticlesOpen {
    overflow: hidden;
    height: 10rem;
    transition-duration: 0.3s;
  }

  .hubSupportTitleLinkBorder {
    width: 100%;
    height: 1px;
    background-color: $color-light-grey;
  }
}

li:last-child .hubSupportTitleLinkBorder {
  display: none;
}

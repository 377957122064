@import '../../../styles/constants/colors';
@import '../../../styles/constants/breakpoints';
@import "../../../styles/mixins/rvs/rvs-mixings";

.messageContainer {
  width: 90%;
  max-width: 482px;
  margin: 77px auto 0;
  text-align: center;

  h1 {
    margin: 18px 0 16px 0;
    color: map-get($legacy-color-names, tooltip);
    font-size: 34px;
    font-style: normal;
    letter-spacing: -0.02em;
    line-height: 41px;
    text-align: center;
  }

  svg {
    height: 50px;
  }
}

.explainText {
  width: 100%;
  font-size: 16px;
  text-align: center;

  a {
    color: inherit;

    @include inline-link();
  }
}


.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 46px;
  gap: 16px;
  text-align: right;
  align-items: center;

  > button {
    width: auto;
    height: auto;
    min-height: unset !important;
    max-height: 40px;
    padding: 9px 15px !important;
    line-height: 1;
    > svg{
      width: 19px;
    }
  }

  .editButton,
  .downloadButton {
    min-height: auto;
    width: unset;
    height: 40px;
    padding: 9px 15px;
    border: 1px solid map-get($hubColors, dark-gray);
    border-bottom-width: 2px;
    background: color(background);
    border-radius: 5px;
    color: color(text);
    line-height: normal;
    transition: 0.2s filter ease-in-out;
    &:hover {
      filter: brightness(90%);
    }
    svg {
      width: 20px;
      height: 20px;
      margin: 0;
    }
    span {
      font-size: 16px;
    }
  }
}
.form {
  margin-top: 66px;
}

.warningIcon {
  width: 50px;
  path {
    fill: #D90429;
  }
}

@use "sass:map";
@import "../../styles/constants/colors";
@import '../../styles/constants/variables';
@import "../../styles/mixins/rvs/rvs-mixings";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.formContainer {
  width: 100%;
  height: 100%;
  font-family: $font-family-text-font;
  letter-spacing: -0.02em;

  .wrapper {
    width: 90%;
    max-width: 490px;
    margin: 0 auto 10.5rem;

    h1 {
      margin: 0 0 10px;
      font-size: 2.125rem;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
    }

    > p {
      margin-bottom: 40px;
    }

    .dateFieldContainer {
      > div {
        width: 100%;
      }

      > div > div > div {
        width: 100%;
      }

      div:nth-of-type(2) {
        div:nth-of-type(2) {
          z-index: 99;
        }
      }
    }

    .input {
      > div > div {
        transform: none;
      }

      label {
        margin: 0 0 4px;
        font-weight: 700;
      }

      input {
        &:not(:disabled) {
          &:hover,
          &:focus {
            border-color: color(text);
          }

          &:focus {
            box-shadow: 0 0 0 1px color(background), 0 0 0 3px map.get($primary-colors, primary), 0 0 0 4px color(background);

            &::placeholder {
              color: $contrast-dark-hover2;
            }
          }
        }

        &::placeholder {
          color: $contrast-light-accent-50;
        }

        /* Hide input number arrows */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          margin: 0;
          appearance: none;
        }

        &[type="number"] {
          appearance: none;
        }
      }
    }

    .fieldset {
      padding: 15px 16px 30px 15px;
      border-radius: 3px;
      margin: 24px 0 0;
      background: color(background);

      &:nth-child(4) {
        margin-bottom: 48px;
      }

      .sectionTitle {
        margin: 0;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 150%;
      }

      .scoreTitle {
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 150%;
      }

      .helpAndSupport {
        text-decoration: underline;
      }
    }

    .finalText {
      margin-bottom: 24px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 150%;

      a {
        @include inline-link;
      }
    }

    .scoreSection {
      .group:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    .actions {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        width: 100%;
        min-width: 265px;
        height: 100%;
        justify-content: space-between;
        padding: 0;
      }

        > button {
          width: auto;
          height: auto;
          min-height: unset;
          padding: 9px 15px;
          line-height: 1;

          > svg{
            width: 19px;
          }
        }

    }

    .cancelButton {
      width: unset;
      height: 40px;
      min-height: auto;
      padding: 9px 15px;
      border: 1px solid map.get($hubColors, dark-gray);
      border-radius: 5px;
      border-bottom-width: 2px;
      background: color(background);
      color: color(text);
      line-height: normal;
      transition: 0.2s filter ease-in-out;

      &:hover {
        filter: brightness(90%);
      }

      svg {
        width: 20px;
        height: 20px;
        margin: 0;
      }

      span {
        font-size: 1rem;
        line-height: normal;
      }
    }

    .checkButton {
      width: unset;
      min-width: 140px;
      margin: 0;

      svg {
        fill: $color-white;
      }
    }
  }

  .errorStrip {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 0 16px;

    svg {
      width: 16px;
      height: 16px;
      margin: 9px 7px 9px 10px;
      fill: white;
    }
  }

  .topText {
    font-size: 1.125rem;
    font-weight: 700;
  }

  .certificateInfo {
    font-size: 1.125rem;
  }
}

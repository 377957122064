@import '../../constants/colors';
/* stylelint-disable css-modules/no-global-scoped-selector */

@mixin inline-link {
  border-bottom: 1px solid lighten(map-get($legacy-color-names, tooltip), 50%);
  font-weight: 600;

  &:hover {
    border-bottom: 2px solid map-get($hubColors, hub_link);
    color: map-get($hubColors, hub_link);
    text-decoration: none;
  }
}
